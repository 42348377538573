import StructureType from "./strucutre.types"
import {
    DateOperators,
    DatetimeOperators,
    ListOperators,
    LotOperators,
    NomenclatorOperators,
    NumberOperators,
    TextOperators,
    TimeOperators
} from "./operator.types";

export const FieldTypes = {
    [StructureType.NUMBER]: NumberOperators,
    [StructureType.LISTSIMPLE]: ListOperators,
    [StructureType.LIST]: ListOperators,
    [StructureType.TEXT]: TextOperators,
    [StructureType.LOTE]: LotOperators,
    [StructureType.PRODUCTSIMPLE]: NomenclatorOperators,
    [StructureType.PRODUCT]: NomenclatorOperators,
    [StructureType.EQUIPMENTSIMPLE]: NomenclatorOperators,
    [StructureType.EQUIPMENT]: NomenclatorOperators,
    [StructureType.OPERATORSIMPLE]: NomenclatorOperators,
    [StructureType.OPERATOR]: NomenclatorOperators,
    [StructureType.SEASONSIMPLE]: NomenclatorOperators,
    [StructureType.SEASON]: NomenclatorOperators,
    [StructureType.INPUTSIMPLE]: NomenclatorOperators,
    [StructureType.INPUT]: NomenclatorOperators,
    [StructureType.MEASUREMENTUNITSIMPLE]: NomenclatorOperators,
    [StructureType.MEASUREMENTUNIT]: NomenclatorOperators,
    [StructureType.DATA]: DateOperators,
    [StructureType.TIME]: TimeOperators,
    [StructureType.DATATIME]: DatetimeOperators
};
