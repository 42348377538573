import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"mb-12 overflow-y-auto",attrs:{"elevation":"0","outlined":""}},[_c(VDataTable,{attrs:{"headers":_vm.groupHeaders,"items":_vm.setStructuresOnTables(_vm.groupDetailsByCompany, 'group_details_name'),"items-per-page":_vm.groupDetailsByCompany.length,"search":_vm.groupStructureSearch,"show-select":"","footer-props":{
      ..._vm.rowsPerPageItemsOptions,
      itemsPerPageText: _vm.$t('general.table.itemsPerPageText'),
      pageText: '{0}-{1} ' + _vm.$t('general.table.pageText') + ' {2}',
    },"height":"350px"},on:{"item-selected":function($event){return _vm.updateGroupStructuresSelected($event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"flex-container",staticStyle:{"width":"100vw","max-width":"100%"}},[_c(VTextField,{staticClass:"required mt-2",attrs:{"onkeypress":"return (event.charCode != 34)","label":_vm.$t('proccess.fields.groupStructureSearch'),"required":"","append-icon":"mdi-magnify","background-color":"#EAEAEA80","color":"secondary","persistent-hint":"","small":"","flat":"","outlined":"","dense":"","small-ships":""},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==86)return null;return _vm.replaceGroupStructureSearch.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==86)return null;if(!$event.ctrlKey)return null;return _vm.replaceGroupStructureSearch.apply(null, arguments)}]},model:{value:(_vm.groupStructureSearch),callback:function ($$v) {_vm.groupStructureSearch=$$v},expression:"groupStructureSearch"}})],1)])])]},proxy:true},{key:"item.structure",fn:function({ item }){return _vm._l((item.structure),function(structure){return _c(VChip,{key:structure.id,staticClass:"ma-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(structure.details_name)+" ")])})}}]),model:{value:(_vm.groupStructuresSelected),callback:function ($$v) {_vm.groupStructuresSelected=$$v},expression:"groupStructuresSelected"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }