import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition-group',{attrs:{"name":"list","tag":"div"}},_vm._l((_vm.value),function(item,index){return _c(VCard,{key:item.id,staticClass:"mt-3",attrs:{"outlined":""}},[_c(VCardTitle,{staticClass:"justify-end pb-0"},[_c(VBtn,{attrs:{"disabled":index + 1 >= _vm.value.length,"icon":""},on:{"click":function($event){return _vm.down(index)}}},[_c(VIcon,[_vm._v(" mdi-arrow-down ")])],1),_c(VBtn,{attrs:{"disabled":index === 0,"icon":""},on:{"click":function($event){return _vm.up(index)}}},[_c(VIcon,[_vm._v(" mdi-arrow-up ")])],1)],1),_c(VCardText,[_vm._t("default",null,{"structure":item,"index":index})],2)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }