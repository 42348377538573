<template>
  <v-card class="mb-12 overflow-y-auto" elevation="0" outlined>
    <v-data-table
      v-model="structuresSelected"
      :headers="detailsHeaders"
      :items="setStructuresOnTables(details, 'details_name')"
      :items-per-page="details.length"
      :search="structureSearch"
      @item-selected="$emit('update:form-validation')"
      show-select
      :footer-props="{
        ...rowsPerPageItemsOptions,
        itemsPerPageText: $t('general.table.itemsPerPageText'),
        pageText: '{0}-{1} ' + $t('general.table.pageText') + ' {2}',
      }"
      height="350px"
    >
      <template v-slot:top>
        <thead>
          <tr>
            <th class="flex-container" style="width: 100vw; max-width: 100%">
              <v-text-field
                onkeypress="return (event.charCode != 34)"
                v-on:keyup.86="replaceStructureSearch"
                v-on:keyup.ctrl.86="replaceStructureSearch"
                v-model="structureSearch"
                :label="$t('proccess.fields.structureSearch')"
                required
                class="required mt-2"
                append-icon="mdi-magnify"
                background-color="#EAEAEA80"
                color="secondary"
                persistent-hint
                small
                flat
                outlined
                dense
                small-ships
              />
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:item.structure_type="{ item }">
        <v-chip class="ma-1" small>
          {{ translateType(item.structure_type) }}
        </v-chip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
  import i18n from "@/plugins/i18n"
  import sortList from "@/services/OrderListService"
  import _ from "lodash"
  import { mapGetters } from "vuex"

  export default {
    name: "DataTableStructureComponent",

    data: () => ({
      structureSearch: "",
      rowsPerPageItemsOptions: {
        itemsPerPageOptions: [10, 20, 50, 100, 200],
      },
    }),

    computed: {
      ...mapGetters({
        details: "details/details",
      }),
      detailsHeaders: () => [
        { text: i18n.t("structure.fields.name"), value: "details_name" },
        {
          text: i18n.t("structure.fields.structure_type"),
          value: "structure_type",
        },
      ],
      structuresSelected: {
        get() {
          return this.$store.getters["proccess/structuresSelected"]
        },
        set(val) {
          this.$store.commit("proccess/SET_STRUCTURES_SELECTED", val)
        },
      },
    },

    methods: {
      setStructuresOnTables(list, order) {
        const data = sortList.orderListByUppercase(list, order)
        return _.filter(data, s => {
          if (s.company && s.company.id) {
            return s.company.id !== 0
          }
        })
      },
      translateType(type) {
        switch (type) {
          case "NUMBER":
            return i18n.t("structure.select.number")
          case "LACRE":
            return i18n.t("structure.select.lacre")
          case "TEXT":
            return i18n.t("structure.select.text")
          case "LIST":
            return i18n.t("structure.select.list")
          case "PRODUCT":
            return i18n.t("structure.select.product")
          case "OPERATOR":
            return i18n.t("structure.select.operator")
          case "EQUIPMENT":
            return i18n.t("structure.select.equipment")
          case "SEASON":
            return i18n.t("structure.select.season")
          case "MEASUREMENTUNIT":
            return i18n.t("structure.select.mu")
          case "INPUT":
            return i18n.t("structure.select.input")
          case "LOTE":
            return i18n.t("structure.select.lote")
          case "DATATIME":
            return i18n.t("structure.select.datetime")
          case "DATA":
            return i18n.t("structure.select.date")
          case "TIME":
            return i18n.t("structure.select.time")
          case "LISTSIMPLE":
            return i18n.t("structure.select.sList")
          case "PRODUCTSIMPLE":
            return i18n.t("structure.select.sProduct")
          case "OPERATORSIMPLE":
            return i18n.t("structure.select.sOperator")
          case "EQUIPMENTSIMPLE":
            return i18n.t("structure.select.sEquipment")
          case "SEASONSIMPLE":
            return i18n.t("structure.select.sSeason")
          case "MEASUREMENTUNITSIMPLE":
            return i18n.t("structure.select.smu")
          case "INPUTSIMPLE":
            return i18n.t("structure.select.sInput")
          case "QR":
            return i18n.t("structure.select.qr")
        }
      },
      replaceStructureSearch(event) {
        this.structureSearch = event.currentTarget.value
          .toString()
          .replaceAll('"', "")
      },
    },
  }
</script>

<style scoped>
  .v-data-table thead tr th {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
  }

  .v-data-table thead tr th .v-input__control {
    border-bottom: none;
  }
</style>
