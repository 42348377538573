import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{staticClass:"ma-0 pa-0 mt-4",attrs:{"cols":"12"}},[_c(VCard,{attrs:{"elevation":"0"}},[_c(VOverlay,{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticStyle:{"z-index":"2"},attrs:{"color":"white","opacity":"0.9"}},[_c(VProgressCircular,{staticClass:"mx-auto",staticStyle:{"left":"150px"},attrs:{"size":"50","indeterminate":"","color":"secondary"}})],1),_c(VTabs,{attrs:{"color":"secondary","hide-slider":"","dark":"","height":"60"},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c(VTab,{staticClass:"ma-0 py-0 px-4",staticStyle:{"color":"#006855"}},[_vm._v(" "+_vm._s(_vm.$t('proccess.titles.main'))+" ")]),_c(VTab,{staticClass:"ma-0 py-0 px-4",staticStyle:{"color":"#006855"},attrs:{"disabled":!_vm.processSelected.id}},[_vm._v(" "+_vm._s(_vm.$t('proccess.titles.rules'))+" ")]),_c(VTabsItems,{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c(VTabItem,{attrs:{"value":0}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('TabMainProcess',{ref:"tabMainProcess",attrs:{"action":_vm.action,"item":_vm.editedItem},on:{"change:tab":function($event){_vm.tabs = $event}}})],1)],1)],1),_c(VTabItem,{attrs:{"value":1}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('TabRulesProcess',{attrs:{"action":_vm.action,"item":_vm.editedItem}})],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }