import { render, staticRenderFns } from "./TabRulesProcess.vue?vue&type=template&id=1044a607&scoped=true&"
import script from "./TabRulesProcess.vue?vue&type=script&lang=js&"
export * from "./TabRulesProcess.vue?vue&type=script&lang=js&"
import style0 from "./TabRulesProcess.vue?vue&type=style&index=0&id=1044a607&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1044a607",
  null
  
)

export default component.exports