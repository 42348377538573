export const OperatorTypes = [
    "MORE_THAN",
    "LESS_THAN",
    "EQUALS_OR_LESS_THAN",
    "EQUALS_OR_MORE_THAN",
    "EQUALS_TO",
    "NOT_EQUALS_TO",
    "CONTAINS",
    "NOT_CONTAINS",
    "STARTS_WITH",
    "ENDS_WITH",
    "CONTAINS_AT_LEAST_ONE",
    "BEFORE",
    "BEFORE_EQUALS",
    "AFTER",
    "AFTER_EQUALS",
    "EQUALS",
    "CURRENT_DATE",
    "ALL_DATES"
];

export const NumberOperators = [
    "MORE_THAN",
    "LESS_THAN",
    "EQUALS_OR_LESS_THAN",
    "EQUALS_OR_MORE_THAN",
    "EQUALS_TO",
    "NOT_EQUALS_TO"
];

export const NomenclatorOperators = [
    "CONTAINS",
    "NOT_CONTAINS",
    "CONTAINS_AT_LEAST_ONE"
];

export const ListOperators = [
    "CONTAINS",
    "NOT_CONTAINS",
    "CONTAINS_AT_LEAST_ONE"
];

export const LotOperators = [
    "EQUALS_TO",
    "NOT_EQUALS_TO",
    "STARTS_WITH",
    "ENDS_WITH",
    "CONTAINS"
];

export const TextOperators = [
    "EQUALS_TO",
    "NOT_EQUALS_TO",
    "STARTS_WITH",
    "ENDS_WITH",
    "CONTAINS"
];

export const DateOperators = [
    "BEFORE",
    "BEFORE_EQUALS",
    "AFTER",
    "AFTER_EQUALS",
    "EQUALS",
    "CURRENT_DATE",
    "ALL_DATES"
];

export const TimeOperators = [
    "BEFORE",
    "BEFORE_EQUALS",
    "AFTER",
    "AFTER_EQUALS",
    "EQUALS",
    "CURRENT_DATE",
    "ALL_DATES"
];

export const DatetimeOperators = [
    "BEFORE",
    "BEFORE_EQUALS",
    "AFTER",
    "AFTER_EQUALS",
    "EQUALS",
    "CURRENT_DATE",
    "ALL_DATES"
];