import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"mb-12 overflow-y-auto",attrs:{"elevation":"0","outlined":""}},[_c(VDataTable,{attrs:{"headers":_vm.detailsHeaders,"items":_vm.setStructuresOnTables(_vm.details, 'details_name'),"items-per-page":_vm.details.length,"search":_vm.structureSearch,"show-select":"","footer-props":{
      ..._vm.rowsPerPageItemsOptions,
      itemsPerPageText: _vm.$t('general.table.itemsPerPageText'),
      pageText: '{0}-{1} ' + _vm.$t('general.table.pageText') + ' {2}',
    },"height":"350px"},on:{"item-selected":function($event){return _vm.$emit('update:form-validation')}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"flex-container",staticStyle:{"width":"100vw","max-width":"100%"}},[_c(VTextField,{staticClass:"required mt-2",attrs:{"onkeypress":"return (event.charCode != 34)","label":_vm.$t('proccess.fields.structureSearch'),"required":"","append-icon":"mdi-magnify","background-color":"#EAEAEA80","color":"secondary","persistent-hint":"","small":"","flat":"","outlined":"","dense":"","small-ships":""},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==86)return null;return _vm.replaceStructureSearch.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==86)return null;if(!$event.ctrlKey)return null;return _vm.replaceStructureSearch.apply(null, arguments)}]},model:{value:(_vm.structureSearch),callback:function ($$v) {_vm.structureSearch=$$v},expression:"structureSearch"}})],1)])])]},proxy:true},{key:"item.structure_type",fn:function({ item }){return [_c(VChip,{staticClass:"ma-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.translateType(item.structure_type))+" ")])]}}]),model:{value:(_vm.structuresSelected),callback:function ($$v) {_vm.structuresSelected=$$v},expression:"structuresSelected"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }