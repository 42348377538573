const StrcutureType = {
    LISTSIMPLE: 'LISTSIMPLE',
    LIST: 'LIST',
    NUMBER: 'NUMBER',
    TEXT: 'TEXT',
    LOTE: 'LOTE',
    PRODUCTSIMPLE: 'PRODUCTSIMPLE',
    PRODUCT: 'PRODUCT',
    EQUIPMENTSIMPLE: 'EQUIPMENTSIMPLE',
    EQUIPMENT: 'EQUIPMENT',
    OPERATORSIMPLE: 'OPERATORSIMPLE',
    OPERATOR: 'OPERATOR',
    SEASONSIMPLE: 'SEASONSIMPLE',
    SEASON: 'SEASON',
    INPUTSIMPLE: 'INPUT',
    INPUT: 'INPUT',
    MEASUREMENTUNITSIMPLE: 'MEASUREMENTUNITSIMPLE',
    MEASUREMENTUNIT: 'MEASUREMENTUNIT',
    DATA: 'DATA',
    TIME: 'TIME',
    DATATIME: 'DATATIME'
};

export default StrcutureType;
